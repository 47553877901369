$(document).ready(function() {
  var pageWidth = $(window).width();
  if (pageWidth <= 992) {

    // $('.nav-link').each(function(i, item){
    //   var newHref = $(this).attr('href');
    //   console.log(newHref, 'sadasd');
    //   $(this).attr('href', newHref.replace('/', '#anchor-').replace('.php', ''));
    // });

    var blocks = [
      "onas",
      "hledate-overene-firmy",
      "inzerujte-u-nas",
      "reference",
      "e-booky-zdarma",
      "kontakty",
    ];

    $.each(blocks, function(i, item){
      //console.log(i, item);

      setTimeout(function(){
        $.ajax({
          url: item + ".php",
          method: 'get',
          dataType: 'html',
          async: false,
          success: function(result){
            var parser = new DOMParser();
            var doc = parser.parseFromString(result, 'text/html');
            var body = doc.querySelector('#bodyContent');

            $('#anchor-'+item).after($(body).toggleClass('container container-fluid'));
          }
        });
      }, i * 100);

    });

  }

  $(document).on('click', "#myModal", function(){
    $(this).css({
      'display': 'none'
    });
  });

  $(document).on('click', '.modal-content-open', function(){
    // Get the modal
    var modal = $("#myModal");

// Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = $(this);
    var modalImg = $("#img01");
    var captionText = $("#caption");

    //console.log('log', img, modalImg, captionText);

    modal.css({
      'display': 'block'
    });
    modalImg.attr('src', img.attr('src'));
    captionText.html(img.attr('alt'));

    // Get the <span> element that closes the modal
    var span = modal.find(".close");

    // When the user clicks on <span> (x), close the modal
    span.on('click', function() {
      modal.css({
        'display': 'none'
      });
    });

  });

  $(document).on('submit', '.check-form', function(e){
    if($('#agree').is(":checked") === false){
      e.preventDefault();
      alert('Pro stažení je nutné Seznámení se zpracováním osobních údajů.');
    }
  });

});